import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgQuizCover from "../../assets/images/gen-mars/mars-quiz/cover.png"

const quizReviewPage = () => (
  <Layout>
    <SEO title="Quiz Review" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #fff"}}>
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgQuizCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative uk-text-center">
                      PEOPLE
                    <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
                  </h2>
                  <p className="mt5 uk-text-large uk-text-bold uk-text-center text-light">
                    Test your Mars sense
                  </p>
                  <p className="text-light uk-text-center mb5">
                    How much do you know about the red planet? Take this quick quiz and find out.
                  </p>
                </div>
                <div className="pa4-ns" style={{maxWidth: "900px", margin: "auto", backgroundColor: "#262626"}}>
                <form>
                    <fieldset className="uk-fieldset">
                        <div className="uk-margin flex flex-column flex-row-ns flex-start">
                            <div className="w-100 w-10-ns pa2">
                            <span className="uk-text-lead stacked-card-count font-nostromo uk-text-bold mr2 uk-visibl@s"  style={{fontWeight: "500", color: "white"}}>
                            X
                            </span>
                            <span className="uk-text-lead stacked-card-count font-nostromo uk-text-bold mr2 uk-hidden@s"  style={{fontWeight: "500", color: "white"}}>
                            ✔
                            </span>
                            <span className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo uk-text-bold"  style={{fontWeight: "500"}}>
                            01
                            </span>
                            </div>
                            <div className="w-100 w-90-ns pa2">
                                <div className="uk-text-large text-light mb4">
                                    Mars is the Xth planet from Sun
                                </div>
                                <label className="w-100 text-highlight-brown"><input className="uk-radio" type="radio" name="radio" /> 2</label><br/><br/>
                                <label className="w-100"><input className="uk-radio" type="radio" name="radio" /> 4</label><br/><br/>
                                <label><input className="uk-radio" type="radio" name="radio" /> 5</label><br/><br/>
                                <label><input className="uk-radio" type="radio" name="radio" /> 6</label>
                            </div>
                            
                        </div>
                        <hr className="custom-hr" style={{borderColor: "black"}} />
                    </fieldset>
                </form>
                <div className="uk-margin uk-text-center">
                    <button className="uk-button uk-button-default" type="button" data-uk-toggle="target: #modal-example">Button</button>
                  </div>
                </div>
            </div>          
          </div>  
      </section>
    </main>
  </Layout>
)

export default quizReviewPage
